<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card>
            <div slot="header">
            <CRow>
                <CCol sm="6">
                    <strong>Insumos</strong>
                </CCol>
                <CCol sm="6" style="text-align:right;">
                    <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                </CCol>
                </CRow>
          </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th></th>
                <th>Tipo</th>
                <th>Nome</th>
                <th>UnC</th>
                <th>{{ this.$session.get('regras')[0].grp_moeda }} / UnC</th>
                <th>UnP</th>
                <th>Valor Final</th>
                <th colspan="2"></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in mat" :key="index">
                    <td>
                      <b-form-checkbox
                        v-model="mat[index].mat_active" 
                        switch
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-select v-model="mat[index].mat_tipo_ID" :options="optionsTipo" size="sm"></b-form-select>
                    </td>
                    <td>
                      <b-form-input v-model="mat[index].mat_name" size="sm"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="mat[index].mat_unc" size="sm"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mat[index].mat_value" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <b-form-select v-model="mat[index].mat_unidade_ID" :options="optionsUnidade" size="sm"></b-form-select>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm" style="font-size:10px;" :options="dinheiro" v-model="mat[index].mat_total" readonly></vue-autonumeric>
                    </td>
                    <td>
                        <button class="btn btn-warning btn-sm" @click="editar(index)" size="sm">Salvar</button>
                    </td>
                    <td>
                        <button class="btn btn-sm" v-b-modal="'modal-detalhes'+index">Detalhes...</button>
                        <b-modal :id="'modal-detalhes'+index" title="Detalhes">
                          <b-row cols="2">
                            <b-col>Vida últi em anos</b-col>
                            <b-col><b-form-input @blur="calculaCustoAnual(index)" type="number" v-model="mat[index].mat_vidautil"></b-form-input></b-col>
                            <b-col>Custo anual manutenção</b-col>
                            <b-col><vue-autonumeric class="form-control input-sm" @blur.native="calculaCustoAnual(index)" :options="dinheiro" v-model="mat[index].mat_custoanual"></vue-autonumeric></b-col>
                            <b-col>Custo total anual</b-col>
                            <b-col><vue-autonumeric readonly class="form-control input-sm" :options="dinheiro" v-model="mat[index].mat_custototal"></vue-autonumeric></b-col>
                            <b-col>Horas Úteis Ano</b-col>
                            <b-col><b-form-input type="number" @blur="calculaCustoHora(index)" v-model="mat[index].mat_horasuteis"></b-form-input></b-col>
                            <b-col>Custo hora</b-col>
                            <b-col><vue-autonumeric class="form-control input-sm" @blur.native="calculaCustoHora(index)" :options="dinheiro" v-model="mat[index].mat_custohora"></vue-autonumeric></b-col>
                            <b-col></b-col>
                            <b-col style="margin-top:10px;"><button class="btn btn-warning btn-sm" @click="editar(index)" size="sm">Salvar</button></b-col>
                          </b-row>
                        </b-modal>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <b-form-select v-model="matTipoID" :options="optionsTipo" size="sm"></b-form-select>
                    </td>
                    <td><b-form-input v-model="matName" size="sm"></b-form-input></td>
                    <td><b-form-input v-model="matUnc" size="sm"></b-form-input></td>
                    <td><vue-autonumeric class="form-control input-sm" style="font-size:10px;" v-model="matValue"></vue-autonumeric></td>
                    <td><b-form-select v-model="matUnidadeID" :options="optionsUnidade" size="sm"></b-form-select></td>
                    <td></td>
                    <td colspan="2"><button class="btn btn-success btn-sm" @click="adicionar()">Adicionar</button></td>
                </tr>
            </tbody>
        </table>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Insumos">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/_mj8p2oq6Mk?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      materialItem: [],
      sessao: '',
      loading: false,
      mat: [],
      matTipoID: 0,
      matName: '',
      matUnc: '',
      matValue: 0,
      matUnidadeID: 0,
      optionsTipo: [],
      optionsUnidade: [],
      matdiv: [],
      matnome: [],
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function () {
      this.optionsUnidade = []
      this.optionsTipo = []
      this.listar('pro_Material', 'mat_tipo_ID, mat_name', '').then(
        (response) => {
        this.mat = response
        this.listar('pro_Material_Tipo', 'mtp_ID', 'mtp_active >= 0').then(
            (r) => {
              for(var i = 0; i < r.length; i++) {
                  this.optionsTipo.push({ value: r[i].mtp_ID, text: r[i].mtp_name, disabled: !r[i].mtp_active })
              }
              this.listar('pro_Material_Unidade', 'mun_ID', 'mun_active >= 0').then(
                (r) => {
                    for(var i = 0; i < r.length; i++) {
                        this.optionsUnidade.push({ value: r[i].mun_ID, text: r[i].mun_name, disabled: !r[i].mun_active })
                        this.matdiv[r[i].mun_ID] = r[i].mun_conv
                        this.matnome[r[i].mun_ID] = r[i].mun_sigla
                    }
                    this.calculaTotal()
                    this.loading = false
                },
                (r) => {
                    this.$notify({
                        type: 'danger',
                        title: 'Dados básicos necessários',
                        text: 'Cadastre unidades de compras antes.'
                    })
                    this.loading = false
                }
              )
          },
          (r) => {
            this.$notify({
                type: 'danger',
                title: 'Dados básicos necessários',
                text: 'Cadastre tipos de materiais antes.'
            })
            this.loading = false
          }
        )
        }, (response) => {})
    },
    adicionar: function () {
      if (this.matUnc === '' || this.matName === '' || this.matValue === 0 || this.matUnidadeID === 0 || this.matTipoID === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material', 'mat_ID', '(mat_name = ' + this.matName + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.matName) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Material', {resource: [ {mat_tipo_ID: this.matTipoID, mat_name: this.matName, mat_unc: this.matUnc, mat_value: this.matValue, mat_unidade_ID: this.matUnidadeID, mat_active: 1} ]}).then(
            (response) => {
              this.matName = ''
              this.matUnc = ''
              this.matValue = 0
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editar: function (index) {
      if (this.mat[index].mat_tipo_ID === '' || this.mat[index].mat_name === '' || this.mat[index].mat_unc === 0 || this.mat[index].mat_value === 0 || this.mat[index].mat_unidade_ID === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material', 'mat_ID', '(mat_name = ' + this.mat[index].mat_name + ') AND (mat_ID != ' + this.mat[index].mat_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.matName) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Material', {resource: [ {mat_tipo_ID: this.mat[index].mat_tipo_ID, mat_name: this.mat[index].mat_name, 
          mat_unc: this.mat[index].mat_unc, mat_value: this.mat[index].mat_value, mat_unidade_ID: this.mat[index].mat_unidade_ID, 
          mat_active: this.mat[index].mat_active, mat_vidautil: this.mat[index].mat_vidautil, 
          mat_custoanual: this.mat[index].mat_custoanual, mat_custototal: this.mat[index].mat_custototal, 
          mat_horasuteis: this.mat[index].mat_horasuteis, mat_custohora: this.mat[index].mat_custohora, mat_total: this.mat[index].mat_total} ]}, 'mat_ID = ' + this.mat[index].mat_ID).then(
            (response) => {
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    calculaCustoAnual: function(index) {
      var m = this.mat[index]
      if (m.mat_value === 0) {
        this.mat[index].mat_custototal = 0
      } else {
        this.mat[index].mat_custototal = ((m.mat_value + (m.mat_vidautil * m.mat_custoanual)) / m.mat_vidautil)
      }
    },
    calculaCustoHora: function(index) {
      var m = this.mat[index]
      if (m.mat_custototal === 0) {
        this.mat[index].mat_custohora = 0
      } else {
        this.mat[index].mat_custohora = (m.mat_custototal / m.mat_horasuteis)
      }
    },
    calculaTotal: function() {
      for (var n = 0; n < this.mat.length; n++) {
        this.mat[n].mat_total = (this.mat[n].mat_value === 0 ? 0 : (this.mat[n].mat_value / this.matdiv[this.mat[n].mat_unidade_ID]))
        if (this.matnome[this.mat[n].mat_unidade_ID] === 'MINUTO') {
          this.mat[n].mat_total = (this.mat[n].mat_custohora / 60)
        }
        if (this.matnome[this.mat[n].mat_unidade_ID] === 'HORA') {
          this.mat[n].mat_total = (this.mat[n].mat_custohora)
        }
      }
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>